<template>
    <div class="videoStatistics">
          <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="关于公约" name="1">
                  <div class="gongyue">
                     <h2 style="text-align:center">精彩视频创作公约</h2> 
                    <strong>【关于公约】</strong> 
                    <p>关于联众创作公约</p>
                    <p>联众致力于为广大用户提供记录和分享美好生活，激发创作的互联网平台。联众制定《精彩视频创作公约》（以下简称为“本公约”），由联众和所有用户共同遵守，共同维护平台的生态健康，营造良好的社区氛围。</p>
                    <p>联众有权根据法律法规、监管政策、行业规范以及联众经营策略的调整变化，单方变更、中止、终止本公约内容，并在相关页面进行公布。 <strong>前述内容一经在相关的页面上公布即有效代替原来的公约内容，同时联众将以适当的方式（包括但不限于弹窗、邮件、站内信、系统通知、平台通知、网站公告等）提醒您更新后的内容</strong> ，以便您及时了解本公约的最新版本。</p>
                    <strong style="text-indent:10px;display:inline-block">对违反本公约的用户，经查证属实，联众有权视情节轻重进行处罚，包括但不限于退回、下线视频内容、停止提供本服务、暂时或永久封禁视频帐号、扣除收益、暂时或永久封禁联众帐号等措施。</strong>
                </div>
                </el-tab-pane>
                <el-tab-pane label="违规内容" name="2">
                  <div class="gongyue">
                  <strong>【视频内容规范要求】</strong> 
                  <strong>1.禁止发布任何违反国家法律法规和相关政策的内容，包括但不限于：</strong>
                  <p>1.1	危害国家统一、主权和领土完整，颠覆国家政权或者泄漏国家秘密、危害国家安全的。</p>
                 <p>	如：反对、攻击、曲解“一个中国”、“一国两制”；反映分裂国家的言行、活动、标识的，包括影像资料、作品、语音、言论、图片、文字、旗帜、标语口号等各种形式。</p>
<p>	  举例：标题、简介、封面、视频内容涉及将台湾、香港、澳门地区和其他国家并列称呼，或者台湾地区、香港特别行政区区旗和各个国家国旗并列。</p>
<p>1.2	宣扬恐怖主义、极端主义或者煽动实施恐怖活动、极端主义活动。</p>
<p>		如不当展示境内外恐怖主义组织、恐怖主义行为及其主张，包括但不限于传播暴恐画面、暴恐组织标志、暴恐旗帜、法西斯暴行、大屠杀行为、人体实验等残暴行为。</p>


<p> </p>
<p>1.3	损害国家荣誉和利益的。</p>
<p>		如贬损、恶搞、损害国家和民族的形象、精神和气质，包括但不限于以焚烧、毁损、涂划、玷污、践踏、恶搞等方式侮辱国旗、国徽、人民币的；在不适宜的娱乐商业活动等场合使用国旗、国徽、人民币的；</p>
<p>		贬损、恶搞、损害革命领袖、英雄烈士人物的形象、名誉；</p>
<p>		贬损、恶搞、损害人民军队、武装警察、国安、公安、司法人员等特定职业、群体以及社会组织、团体的公众形象、名誉；</p>
<p>	举例：如在严肃的纪念场所以不雅行为拍照留念，侮辱、亵渎烈士。</p>
<p>1.4	威胁生命健康的。</p>
<p>	  宣扬制作、销售、使用枪支、弹药、非法药品、毒品、化学品等危险违禁物品的内容；非法药品、化学品包括但不限于笑气、迷幻药、海洛因、罂粟、摇头丸、致幻蘑菇等。</p>
<p>	  表演危害他人人身安全的内容，如：殴打他人、威胁他人等；</p>
<p>	  表演危害自身安全的内容，如自残、自杀、食用明显有害身体健康的食物等。</p>
<p>1.5	煽动民族仇恨、民族歧视、破坏民族团结，损害民族风俗、习惯，或者损害民族优秀文化传统的。</p>
<p>1.6	违背国家宗教政策，宣扬邪教和封建迷信的。</p>
<p>	  煽动破坏国家宗教政策，宣扬宗教狂热，危害宗教和睦，伤害信教公民宗教感情，破坏信教公民和不信教公民团结；</p>
<p>	  宣扬邪教，展示宗教极端主义和邪教组织及其主要成员的活动，以及他们的“教义”与思想。</p>
<p>1.7	与时事热点相关的内容。</p>
<p>	  禁止无新闻采编资质的个人账号发布时政内容；</p>
<p>	  禁止对时事热点内容进行恶意剪辑调侃及不尊重客观的讲解和另类解读。</p>
<p>1.8	法律、法规和国家规定禁止的其他内容：</p>
<p>	  宣扬赌博，备注赌博联系信息的（包括线上赌博及千术教学）；</p>
<p>	  宣传（代办代开）假证、假文凭、假发票等；</p>
<p>	  非法公开或买卖个人信息；</p>
<p>	  非法网站：如色情网站、网络代理软件网站（如VPN)、违规物品交易网站等；</p>
<p>	  不正当交友行为：宣传有偿交友等交易信息以及其他不正当交友行为内容；</p>
<p>	  非法工具、网络诈骗、传销等内容：</p>
<p>①销售、生产、推广非法设备/软件（如病毒软件、窃听器、翻墙软件、针孔摄像头）等内容；</p>
<p>②买卖非法设备（作弊外挂设备、信号干扰器、网络黑卡、开锁工具等）；</p>
<p>③涉及介绍互联网非法投资平台、诈骗网赚平台、诈骗兼职项目等疑似网络诈骗内容；</p>
<p>④宣传、推广传销组织，引诱他人加入。</p>
<p>	  破坏生态环境，虐待动物，捕杀、食用国家保护类动物的内容：</p>
<p>①包括捕捉、家庭饲养、非法买卖或街头违规杂耍国家级保护动物；</p>
<p>②其他非法捕捞及售卖、烹饪、加工、食用濒危野生动植物及其制品等违法行为。</p>
<p>	  违反国家有关规定，滥用、错用特定标识、呼号、称谓、用语。</p>
<p>	  涉及中国地图残缺、地名不完整、地名篡改等违法违规内容。</p>
<strong>2.	禁止任何低俗色情内容，包括但不限于：</strong>
<p>	  直接暴露和描写人体性部位的内容；</p>
<p>	  以带有性暗示、性挑逗的语言描述性行为、性过程、性方式的内容或影视片段；</p>
<p>	  以性取悦为目的使用或展示性玩具、成人用品；</p>
<p>	  带有侵犯个人隐私性质的走光、偷拍、漏点等内容；</p>
<p>	  传播一夜情、换妻、性虐待等有害信息；</p>
<p>	  色情动漫、电影；</p>
<p>	  相关部门禁止传播的色情和有伤社会风化的文字、音视频内容（包括一些电影的删节片段）；</p>
<p>	  非法性药品广告和性病治疗广告等相关内容；</p>
<p>	  推介淫秽色情网站和网上低俗信息的链接、图片、文字等内容；</p>
<p>	  任何涉及未成年人的色情内容；</p>
<p>	  其他带有色情低俗信息的内容。</p>
<strong>3.	禁止任何有社会危害性质的行为、内容，包括但不限于：</strong>
<p>	  具有危险性、威胁性，会使受害者的人身安全受到威胁的内容（如杀人通告）；</p>
<p>	  展示如何实施人身伤害行为或制作会造成人身伤害的物品的指导性内容（如易燃易爆物品、枪支、有毒化学品的制作教程）；</p>
<p>	  宣扬美化暴力事件；</p>
<p>	  展示如何进行网络攻击、个人信息窃取等危害网络环境和与个人人身安全的指导性内容（如通过网络对他人的个人电脑发起攻击、黑客、人肉的内容）。</p>
<strong>4.	禁止任何含有恐怖血腥等等可能对普通观看者造成强烈感官上和精神上的刺激等引人不适内容，包括但不限于：</strong>
<p>	  猎奇、暴力性残虐、出血、内脏露出、身体残缺等血腥内容；</p>
<p>	  渲染实施暴力，直接施行暴力的画面，如砍杀，枪击。</p>
<strong>5.	关于封建、迷信内容 </strong>
<p>	  联众禁止发布宣扬封建、迷信的内容，相关内容禁止如下行为，包括但不限于：</p>
<p>	  禁止通过宣扬迷信行为获取利益；</p>
<p>	  禁止收徒、开班等行为；</p>
<p>	  禁止宣传替代医学；</p>
<p>	  禁止传播涉及公共安全事件的阴谋论和谣言。</p>
<strong>6.	禁止发布任何仇恨性言论内容，或藉由相关内容挑拨矛盾，煽动对立、仇恨情绪。包括但不限于：</strong>
<p>	  对特定群体使用带有侮辱性的称呼；</p>
<p>	  使用诋毁性内容鼓动对特定群体的仇恨。</p>
<strong>7.	禁止任何危害网络安全的内容、行为，包括但不限于：</strong>
<p>	  售卖、传播破解软件或硬件以及相关具有教学性质的内容；</p>
<p>	  宣传游戏外挂、私服等内容；</p>
<p>	  上传存在木马、病毒等非法网站信息内容；</p>
<p>	  其他涉嫌违反破坏计算机信息系统相关法案的。</p>
<p></p>
<strong>【未成年人保护内容规范】</strong>
<p>联众禁止发布影响未成年人身心健康成长的内容。包括但不限于：</p>
<p>1.涉及危害到未成年人人身安全的暴力、危险行为：</p>
<p>	  涉及打架斗殴、校园暴力、辱骂等；</p>
<p>	  危险行为包括未成年人驾驶机动车，燃放烟花爆竹等；</p>
<p>2.涉及未成年人违法违规：</p>
<p>	  涉及未成年人早恋早婚早孕等行为；</p>
<p>	  涉及雇佣、招聘童工；</p>
<p>	  涉及未成年人吸毒、赌博；</p>
<p>3.涉及未成年人不良行为：</p>
<p>	  未成年涉及食用非正常食物、过度饮食等不健康生活方式；</p>
<p>	  向未成年人宣传拜金主义、炫富行为；</p>
<p>	  宣传鼓励未成年人离家出走、辍学；支持未成年读书无用论；</p>
<p>	  引诱未成年人直播打赏、游戏充值；</p>
<p>	  涉及展示、支持、宣扬未成年人不当行为（如模仿成年人抽烟、喝酒、纹身）；</p>
<p>4.涉及侵犯未成年人个人隐私，成人对未成年人的不良行为或联想：</p>
<p>	  涉及成年人对未成年人的不良行为或联想，成年人对未成年人进行过度亲密动作（如亲吻、爱抚、展示成人用品等）；</p>
<p>	  向未成年人展示不雅手势或动作；</p>
<p>	  未成年人asmr或不雅动作等。</p>
<p>5.未成年人性相关，涉及展示、宣扬未成年性行为、性交易、性怪癖、恋童癖等；</p>
<p>6.易引起未成年人观看不适的邪典内容；</p>
<p>7.其他影响未成年人心理健康的内容。</p>
<p></p>
<strong>【知识产权保护】 </strong>
<strong>禁止发布侵犯著作权的稿件，包括但不限于：</strong>
<p>1.未经著作权人许可，传播、贩卖其已公开或尚未公开的正版/盗版视听作品；</p>
<p>2.抄袭或未经著作权人允许使用其作品或素材，如洗稿等；</p>
<p>3.冒充著作权人和/或其他相关权益方；</p>
<p>4.搬运著作权人禁止转载的内容或搬运站内长期活跃的创作者稿件。</p>
<p> </p>
<p>【社区规范要求】</p>
<p>1.	禁止发布对他人具有攻击性的内容，包括但不限于：</p>
<p>	  直接对他人进行言语骚扰或语言人身攻击或人肉搜索的；</p>
<p>	  鼓励、煽动观看者采取言语骚扰或者攻击行为；</p>
<p>	  嘲讽、挑拨他人矛盾的行为；</p>
<p>	  以态度不实、带有嘲讽玩弄意味的表达方式发表言论，并意图造成特定矛盾的行为。</p>
<p>2.	禁止任何旨在恶意冒充他人或频道的内容，包括但不限于：</p>
<p>	  冒用他人的真实姓名、照片；</p>
<p>	  使用某个人的用户名、头像、个签、图片、品牌或者其他个人信息，以此假装、冒充为本站特定用户或者涉及官方业务的账号进行投稿、发布评论等活动；</p>
<p>	  蓄意使发布的稿件像是他人发布的。</p>
<p>如您发现有用户冒用了您的信息或是录制/发布了威胁到您个人隐私的视频请立刻举报，联众会在核实后将其移除。</p>
<p>3.	禁止发布与事实相悖的，涉及完全凭空杜撰、拼凑、编造的谣言和不实信息，包括但不限于：</p>
<p>	  对于难以证实真伪性的稿件联众会考虑酌情添加争议标识；</p>
<p>	  对于被明确证实为谣言和不实信息的稿件联众会进行驳回处理。</p>
<p>4.禁止使用带有强烈情感宣泄的词汇和语句，通过粗俗的口头禅、粗口、网络用语表达观点态度。包括但不限于：</p>
<p>	  谩骂性标题；</p>
<p>	  涉及性器官的辱骂；</p>
<p>	  带有恶意的动作、粗俗文字；</p>
<p>	  骂人片段；</p>
<p>	  带有大量粗俗歌词的音乐。</p>
<p>【执行】</p>
<p>本公约自公布之日起执行。</p>
</div>
                </el-tab-pane>
          </el-tabs>
    </div>
</template>
<style lang="less">

</style>
<script>

export default {
  name: "videoStatistics",
  data() {
    return {
      activeName: '1'
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    }
  }
}
</script>
<style lang="less">
  .gongyue{
    >strong{
      padding-top:10px;

    }
    >p{
      padding-top:10px;
      text-indent: 10px;
      line-height:28px;
    }
  }
</style>